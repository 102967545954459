import React, { useEffect, useState } from "react";
import GetApiCall from "../../helpers/GetApi";
import "./ParallaxSection.css";
// import { isMobile } from "react-device-detect";kw
import { Skeleton } from "antd";
export default function ParallaxSection() {
  const [banners, setBanners] = useState([]);
  useEffect(() => {
    GetApiCall.getRequest("GetBannersV1").then((results) =>
      results.json().then((obj1) => {
        if (results.status == 200 || results.status == 201) {
          var filterBanner = obj1.data.filter(
            (item) => item.location === "Parallax"
          );
          setBanners(filterBanner);
        }
      })
    );
  }, []);
  return (
    <div className="px-lg-4 mobile-padding">
      {banners.length > 0 ? (
        <section
          className="parallax-section  mb-lg-4"
          style={{
            // background: `url(${banner2}) center`,
            background: `url(${window.innerWidth > 568 ? banners[0].image.split("$")[0].split("#")[0] :
              banners[0].image.split("$")[1].split("#")[0]})center`,
          }}>
          {/* <h2>{banners[0]?.heading}</h2>
          <h3>{banners[0]?.title}</h3> */}
          <h1>We make your wings, nested in envelopes & framed in memories.</h1>
          {/* <div className="mt-4 mt-lg-5">
              <a href={banners[0]?.slug} className="theme-btn">
                Explore More
              </a>
            </div> */}
        </section>
      ) : (
        <Skeleton.Image className="w-100 parallax-section mt-5 mb-4" />
      )}
    </div>
  );
}
