import { createContext, useState } from "react";

export const store = createContext()

const Provider = ({
    children
}) => {
    const [logo, setLogo] = useState("");
    const [clientData, setClientData] = useState([]);
    const [vertical, setVertical] = useState([]);
    const [cart, setcart] = useState(0);
    const [cartamount, setcartamount] = useState(0);
    const [banner, setBanner] = useState([]);
    const [category4Data, setCategory4Data] = useState([]);
    const [showPincodeModal, setShowPincodeModal] = useState(false);
    const [pincode, setPincode] = useState(null);
    const [currentPage, setCurrentPage] = useState("Home");
    const [loggedIn, setLoggedIn] = useState(false);
    const [siteHeader, setSiteHeader] = useState([]);
    const [cartItems, setCartItems] = useState([]);
    const [cartData, setCartData] = useState([]); //for cart page
    const [storeStatus, setStoreStatus] = useState("");
    const [selectedFontid, setSelectedFontid] = useState("");
    const [selectedWishesid, setSelectedWishesid] = useState("");
    const [perosnalizationText, setPerosnalizationText] = useState("");
    const [showPersonalize, setShowPersonalize] = useState(true);
    const [greetOptions, setGreetOptions] = useState([])
    const [totalOrders, setTotalOrders] = useState("")
    const [selectedGreetingFontid, setSelectedGreetingFontid] = useState("");




    return (
        <store.Provider value={{
            vertical, setVertical,
            cart, setcart, cartamount, setcartamount, currentPage, setCurrentPage, banner, setBanner, logo, setLogo,
            showPincodeModal, setShowPincodeModal,
            pincode, setPincode,
            clientData, setClientData,
            loggedIn, setLoggedIn,
            siteHeader,
            setSiteHeader,
            cartItems,
            setCartItems,
            category4Data,
            setCategory4Data,
            cartData,
            setCartData,
            storeStatus,
            setStoreStatus,
            selectedFontid,
            setSelectedFontid,
            selectedWishesid,
            setSelectedWishesid,
            perosnalizationText,
            setPerosnalizationText,
            showPersonalize,
            setShowPersonalize,
            greetOptions,
            setGreetOptions,
            totalOrders,
            setTotalOrders,
            selectedGreetingFontid,
            setSelectedGreetingFontid
        }}>
            {children}
        </store.Provider>
    )
}

export default Provider;