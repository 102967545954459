import Slider from "react-slick";
import "./FooterPolicy.css";
import supportIcon from "../../assets/Images/footer-policy-icons/24-hours-support.png";
import easyIcon from "../../assets/Images/footer-policy-icons/easy.png";
import freeDeliveryIcon from "../../assets/Images/footer-policy-icons/free-delivery.png";
import securePaymentIcon from "../../assets/Images/footer-policy-icons/secure-payment.png";
import { HiOutlineChevronLeft, HiOutlineChevronRight } from "react-icons/hi2";
function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className={className} style={{ ...style }} onClick={onClick}>
      <HiOutlineChevronRight />
    </div>
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className={className} style={{ ...style }} onClick={onClick}>
      <HiOutlineChevronLeft />
    </div>
  );
}
export default function FooterPolicy() {
  const productslider = {
    dots: false,
    // infinite: false,
    arrows: false,
    speed: 300,
    // autoplay: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 4,

    responsive: [
      {
        breakpoint: 1290,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 2,
          initialSlide: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          arrows: true,
          nextArrow: <NextArrow />,
          prevArrow: <PrevArrow />,
        },
      },
    ],
  };
  return (
    <div className="container-fluid px-lg-3 mt-lg-4 mb-4 footer-policy-section">
      <div className="row">
        <div className="col-12">
          <Slider {...productslider}>
            <div className="card">
              <div className="card-body">
                <img
                  src={supportIcon}
                  alt="customer-support"
                  className="img-fluid"></img>
                <p className="">
                  <b className="">Customer Support</b>
                  <br />
                  <span>
                    Need assistance? Call us and our team will help you out.
                  </span>
                </p>
              </div>
            </div>
            <div className="card">
              <div className="card-body">
                <img
                  src={securePaymentIcon}
                  alt="Secure Payment"
                  className="img-fluid"></img>
                <p className="">
                  <b className="">Secure Payment</b>
                  <br />
                  <span>
                    Making online payments on our site to buy is Safe and
                    Secure.
                  </span>
                </p>
              </div>
            </div>

            <div className="card">
              <div className="card-body">
                <img
                  src={freeDeliveryIcon}
                  alt="Free Shipping"
                  className="img-fluid"></img>
                <p className="">
                  <b className="">Free Shipping</b>
                  <br />
                  <span>
                    Shipping cost is free for selected value of orders made.
                  </span>
                </p>
              </div>
            </div>

            <div className="card">
              <div className="card-body">
                <img
                  src={easyIcon}
                  alt="easyReturn"
                  className="img-fluid"></img>
                <p className="">
                  <b className="">100% Refund</b>
                  <br />
                  <span>This policy applies only to damaged items.</span>
                </p>
              </div>
            </div>
          </Slider>
        </div>
      </div>
    </div>
  );
}
