import Notiflix from "notiflix";
import { Container, Row } from "react-bootstrap";
import { LuIndianRupee } from "react-icons/lu";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import { store } from "../../context/store";
import PostApiCall from "../../helpers/Api";
import { IoMdCart } from "react-icons/io";
import "./CustomerProfilePage.css";
import { useContext, useEffect, useState } from "react";

export default function AllOrders() {
  const [orderHistoryData, setOrderHistoryData] = useState([]);
  const { setTotalOrders } = useContext(store);

  useEffect(() => {
    Notiflix.Loading.Init({
      svgColor: "#0f3d74",
    });

    var customer = localStorage.getItem("CustomerData");
    var CustomerDetails = JSON.parse(customer);

    PostApiCall.postRequest(
      {
        WhereClause: `where fld_customer_id=${parseInt(
          CustomerDetails[0].fld_customerid
        )}`,
        select: `*`,
      },
      "GetCustomerOrderDetails"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          setOrderHistoryData(obj.data);
          setTotalOrders(obj.data[0]);
          Notiflix.Loading.Remove();
        }
      })
    );
  }, []);
  return (
    <div className="px-lg-3">
      <Container fluid>
        <Row>
          <div className="col-12 my-4 blank-section ">
            <div className="w-100">
              <h2 className="text-center mb-4">All Orders</h2>
            </div>
            {orderHistoryData.length
              ? orderHistoryData.map((data) => {
                return (
                  <>
                    <div className="order-card row mb-4">
                      <div className="col-lg-3">
                        <div className="border">
                          <div className="fields">
                            <lable>Order No:</lable>
                            <p>{data.fld_order_number}</p>
                          </div>
                          <div className="fields">
                            <lable>Order Date:</lable>
                            <p>{data.fld_order_date}</p>
                          </div>
                          <div className="fields">
                            <lable>Order Type:</lable>
                            <p>{data.fld_payment_mode}</p>
                          </div>
                          <div className="fields">
                            <lable>No. of Items:</lable>
                            <p>{data.total_items}</p>
                          </div>
                          <div className="fields">
                            <lable>Amount:</lable>
                            <p>
                              <LuIndianRupee />{" "}
                              {parseFloat(data.fld_order_amount).toFixed(2)}
                            </p>
                          </div>
                          <div className="fields">
                            <lable>Payment Mode:</lable>
                            <p>{data.fld_payment_mode}</p>
                          </div>
                          <div className="fields">
                            <lable>Status:</lable>
                            <p>{data.fld_order_status}</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-9 mt-4 mt-lg-0">
                        <Swiper
                          spaceBetween={20}
                          slidesPerView={2}
                          // centeredSlides
                          breakpoints={{
                            640: {
                              slidesPerView: 2,
                              spaceBetween: 20,
                            },
                            768: {
                              slidesPerView: 3,
                              spaceBetween: 40,
                            },
                            1024: {
                              slidesPerView: 4,
                              spaceBetween: 50,
                            },
                          }}
                          onSlideChange={() => console.log("slide change")}
                          onSwiper={(swiper) => console.log(swiper)}>
                          {JSON.parse(data.item_details)?.map((dat) => {
                            return (
                              <SwiperSlide>
                                <div className="order-inner-card">
                                  <img
                                    src={dat.fld_imageurl}
                                    className="img-fluid"
                                  />
                                  <div className="content">
                                    <p>{dat.fld_variantname}</p>
                                    <div>
                                      <span>Quantity: </span>
                                      {dat.fld_quantity}
                                    </div>
                                    <div>
                                      <span>Amount: </span>
                                      <LuIndianRupee />{" "}
                                      {parseFloat(
                                        dat.fld_website_discount_price
                                      ).toFixed(2)}
                                    </div>
                                  </div>
                                </div>
                              </SwiperSlide>
                            );
                          })}
                        </Swiper>
                      </div>
                    </div>
                  </>
                );
              })
              : ""}
          </div>
        </Row>
      </Container>
    </div>
  );
}
