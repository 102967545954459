import { Collapse } from "antd";
import React, { useEffect, useState } from "react";
import GetApiCall from "../../helpers/GetApi";
import GetSeoData from "../../helpers/GetSeoData";
import "./Faq.css";
export default function Faq() {
  const [Faq, setFaq] = useState([]);
  useEffect(() => {
    GetApiCall.getRequest("GetFAQS").then((results) => {
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          setFaq(obj.data);
        }
      });
    });
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <>
      <GetSeoData type="FAQ" id={null} />
      <section className="section-spacing-x section-spacing-x-mobile py-4">
        <div className="container-fluid">
          <div className="row">
            {/* <div className="col-lg-5">
              <img
                // src={FAQIm}
                className="img-fluid w-100 h-100"
                alt=""
              />
            </div> */}
            <div className="col-lg-12 faq-accordion">
              <h3 className="mb-0 main-heading">
                We have answered few of the commonly asked questions
              </h3>
              {/* <Collapse accordion items={items} /> */}
              {Faq.length ? (
                Faq?.map((data) => {
                  return (
                    <Collapse
                      accordion
                      items={[
                        {
                          key: data.fld_id,
                          label: <h6 className="mb-0">{data.fld_question}</h6>,
                          children: data.fld_answer,
                        },
                      ]}
                    ></Collapse>
                  );
                })
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
