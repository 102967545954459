var urlString = "https://api.bechofy.in/cuckoo-crow-api/";
//var urlString = process.env.REACT_APP_API;
// var urlString = "http://localhost:3025/cuckoo-crow-api/";
// var urlString = "http://192.168.1.72:3022/cuckoo-crow-api/";
const GetApiCall = {
  getRequest(url) {
    return fetch(urlString + url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "*",
        "Content-Type": "application/json",
        "x-auth-token": JSON.parse(localStorage.getItem("access")),

      },
    })
      .then((response) => {
        // console.log(response)
        return response;
      })
      .catch((error) => {
        console.log("request failed", error);
        return error;
      });
  },
};

export default GetApiCall;
