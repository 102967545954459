import moment from "moment";
import Notiflix from "notiflix";
import React, { useContext } from "react";
import { FiMinus, FiPlus } from "react-icons/fi";
import { MdDeleteForever } from "react-icons/md";
import { store } from "../../context/store";
import PostApiCall from "../../helpers/Api";
import "./AddToCartQty.css";
function AddToCartQty(props) {
	const quantity = props.quantity;
	const itemid = props.itemid;
	const cartlineid = props.cartlineid;
	const { setLoginPopupWebsite, setCartData, cart, setcart, setcartamount,
		setCartItems,
		setCategory4Data, setCartItemBeforeLogin } = useContext(store);
	const GetCartdetails = () => {
		var Order = localStorage.getItem("OrderData");
		var Orderdetails = JSON.parse(Order);
		var orderid = 0;
		if (props.shippingAddress) {
			AddShippingChrg()
		}
		if (localStorage.getItem("OrderData") !== null) {
			orderid = Orderdetails[0].order_id;
		}
		// if (localStorage.getItem("CustomerData") === null) {
		// 	setLoginPopupWebsite(true);
		// } else {
		var login = localStorage.getItem("CustomerData");
		var logindetails = JSON.parse(login);
		if (localStorage.getItem("OrderData") !== null) {
			var Order = localStorage.getItem("OrderData");
			var Orderdetails = JSON.parse(Order);
			PostApiCall.postRequest(
				{
					orderid:
						localStorage.getItem("OrderData") == null
							? null
							: Orderdetails[0].order_id,
					id:
						localStorage.getItem("CustomerData") == null
							? null
							: logindetails[0].fld_customerid,
				},
				"GetCartWebsite"
			)
				.then((res) => res.json())
				.then((res1) => {
					if (res1.data.length) {
						setcart(res1.data.reduce((a, v) => (a = a + v.fld_quantity), 0));
						setCartItems(res1.data);
						setCartData(res1.data)
						setcartamount(res1.data[0]?.fld_cart_amount);
					}
					else {
						localStorage.removeItem("OrderData")
						setcart(res1.data.reduce((a, v) => (a = a + v.fld_quantity), 0));
						setCartItems(res1.data);
						setCartData(res1.data)
					}
				});
		}
		// }
		PostApiCall.postRequest(
			{
				customerid:
					localStorage.getItem("CustomerData") == null
						? null
						: logindetails[0].fld_customerid,
				categoryid: null,
				productid: null,
				variantid: null,
				sectionname: "Category4",
				orderid: orderid,
			},
			"GetWebsiteHomePageItems"
		).then((results) => {
			results.json()?.then((obj) => {
				if (results.status == 200 || results.status == 201) {
					// ;
					setCategory4Data(obj.data);
				}
			});
		});
	};
	const deletecart = () => {
		Notiflix.Loading.Dots();
		var Order = localStorage.getItem("OrderData");
		var login = localStorage.getItem("CustomerData");
		var Orderdetails = JSON.parse(Order);
		var logindetails = JSON.parse(login);
		var orderid = 0;
		if (localStorage.getItem("OrderData") !== null) {
			orderid = Orderdetails[0].order_id;
		}
		PostApiCall.postRequest(
			{
				cartID: orderid,
				customerid:
					localStorage.getItem("CustomerData") == null
						? null
						: logindetails[0].fld_customerid,
				cartlineid: cartlineid,
				stateid: 0,
			},
			"DeleteCart"
		).then((result) =>
			result.json().then((obj3) => {
				if (result.status == 200 || result.status == 201) {
					Notiflix.Loading.Remove();
					Notiflix.Notify.Success("Product is successfully deleted.");
					GetCartdetails();
				} else {
					Notiflix.Loading.Remove();
					Notiflix.Notify.Failure(obj3.data);
				}
			})
		);
	};

	const addtocart = (qty) => {
		Notiflix.Loading.Dots("");
		var login = localStorage.getItem("CustomerData");
		var logindetails = JSON.parse(login);
		var Order = localStorage.getItem("OrderData");
		var Orderdetails = JSON.parse(Order);
		// if (logindetails === null) {
		// 	setLoginPopupWebsite(true);
		// 	setCartItemBeforeLogin(itemid);
		// } else {
		// ===========with Login=========
		PostApiCall.postRequest(
			{
				orderdate: moment().format("lll"),
				itemdetails: `
                     [
                     {
                         "Item":${itemid},
                         "Quantity": ${qty}
                     }
                     ]
                     `,
				status: "INCART",
				customerid:
					localStorage.getItem("CustomerData") == null
						? null
						: logindetails[0].fld_customerid,
				createdon: moment().format("lll"),
				updatedon: moment().format("lll"),
				orderid:
					localStorage.getItem("OrderData") == null
						? 0
						: Orderdetails[0].order_id,
				updatedby:
					localStorage.getItem("CustomerData") == null
						? null
						: logindetails[0].fld_userid,
			},
			"AddToCartWebsite"
		).then((result) =>
			result.json().then((obj) => {
				if (result.status == 200 || result.status == 201) {
					if (qty == 1) {
						Notiflix.Notify.Success("Product added to Cart.");
					}
					if (localStorage.getItem("OrderData") == null) {
						localStorage.setItem("OrderData", JSON.stringify(obj.data));
					}
					GetCartdetails();

					Notiflix.Loading.Remove();
				} else {
					Notiflix.Loading.Remove();
					Notiflix.Notify.Failure(obj.data);
				}
			})
		);
		// }
	};
	const AddShippingChrg = () => {
		const det = localStorage.getItem("CustomerData");
		const details = JSON.parse(det);
		var Order = localStorage.getItem("OrderData");
		var Orderdetails = JSON.parse(Order);
		PostApiCall.postRequest(
			{
				customerid:
					details[0].fld_customerid,
				orderid: localStorage.getItem("OrderData") == null
					? 0
					: Orderdetails[0].order_id,
				addressid: props?.shippingAddress,
			},
			"AddShippingChrg"
		).then((results) =>
			results.json().then((obj) => {
				if (
					results.status === 200 ||
					results.status === 201
				) {
					// GetCartdetails();
				}
			})
		);
	}
	return (
		<>
			<div className="_quantity-wrapper">
				<div className="d-flex justify-content-between">
					<h6 className="price price--end">
						<div className="_quantity-wrapper">
							{/* <label className="visually-hidden" htmlFor="Quantity-1">
								Quantity
							</label> */}
							<quantity-input className="quantity-checkout" type="text">
								<button
									className="quantity__button no-js-hidden listing-page"
									name="minus"
									type="button"
									onClick={() => { addtocart(-1) }
									}
								>
									<FiMinus />
								</button>
								<input
									className="quantity__input"
									type="text"
									value={quantity}
									readOnly
									min="0"
									id="Quantity-1"
									data-index="1"
								/>
								<button
									className="quantity__button no-js-hidden listing-page"
									name="plus"
									type="button"
									onClick={() => { addtocart(1) }
									}
								>
									<FiPlus />
								</button>
							</quantity-input>
							<div className=" checkout-delete">
								<cart-remove-button
									id="Remove-1"
									data-index="1"
									className="Remove-1"
									onClick={deletecart}>
									<MdDeleteForever />
								</cart-remove-button>
							</div>
						</div>
					</h6>
				</div>
			</div>
		</>
	);
}

export default AddToCartQty;
