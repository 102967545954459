import React, { useState, useEffect } from "react";
import PostApiCall from "../../helpers/Api";
import Notiflix from "notiflix";
import { Modal } from "react-bootstrap";
import { MdOutlineLockReset } from "react-icons/md";
var otp = Math.floor(100000 + Math.random() * 900000);

export default function ForgotPassword({ ToggleForgotModal }) {
	const [isPasswordVisible, setIsPasswordVisible] = useState(false);
	const [EmailRegex] = useState(
		/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
	);
	const [UrlRegex] = useState(
		/^(https:\/\/www\.|httpss:\/\/www\.|https:\/\/|httpss:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/
	);
	const [PasswordRegex] = useState(
		/^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})/
	);
	const [Email, setEmail] = useState("");
	const [EnteredOtp, setEnteredOtp] = useState(null);
	const [ShowInputforOtp, setShowInputforOtp] = useState(false);
	const [openPassword, setOpenPassword] = useState(false);
	const [NewPassword, setNewPassword] = useState("");
	const [ConfirmPassword, setConfirmPassword] = useState("");
	const [Salt, setSalt] = useState([]);
	const [openModal, setOpenModal] = useState(false);
	const [showField, setShowField] = useState(true);

	useEffect(() => {
		Notiflix.Loading.Init({
			svgColor: "#0f3d74",
		});
	}, []);

	const handleToggleModal = () => {
		setOpenModal(false);
	};

	const sendConfirmation = () => {
		if (Email != "") {
			if (EmailRegex.test(Email)) {
				onPostSalt();
			} else {
				Notiflix.Notify.Failure("Please enter valid Email Address.");
			}
		} else {
			Notiflix.Notify.Failure("Please enter your Email.");
		}
	};

	const onPostSalt = () => {
		Notiflix.Loading.Dots("Please wait...");
		PostApiCall.postRequest(
			{
				email: Email,
			},
			"Get_SaltByEmailId_ForgotPassword"
		).then((results) =>
			results.json().then((obj) => {
				if (results.status == 200 || results.status == 201) {
					if (obj.data != null) {
						setSalt(obj.data);
						onConfirmation();
						Notiflix.Loading.Remove()
					}
				} else {
					Notiflix.Notify.Failure("Email Id is not registered with us");
					Notiflix.Loading.Remove()
				}
			})
		);
	};

	const onConfirmation = () => {
		Notiflix.Loading.Dots("Please wait...");
		PostApiCall.postRequest(
			{
				OTP: otp,
				email: Email,
			},
			"Mailer_ForgotPassword"
		).then((result) => {
			Notiflix.Loading.Remove();
			Notiflix.Notify.Success(
				"Your verification OTP is Successfully sent to your Email Id."
			);
			setShowField(false);
			setShowInputforOtp(true);
		});
	};

	const VerifyOtp = () => {
		if (EnteredOtp != null) {
			if (EnteredOtp == otp) {
				setOpenPassword(true);
				setShowInputforOtp(false);
			} else {
				Notiflix.Notify.Failure("Incorrect OTP.");
			}
		} else {
			Notiflix.Notify.Failure("Please enter OTP.");
		}
	};

	const SavePassword = () => {
		if (NewPassword != "") {
			if (PasswordRegex.test(NewPassword)) {
				if (ConfirmPassword != "") {
					if (ConfirmPassword == NewPassword) {
						onPostPassword();
					} else {
						Notiflix.Notify.Failure(
							"New Password and Confirm Password do not match "
						);
					}
				} else {
					Notiflix.Notify.Failure("Please enter confirm password.");
				}
			} else {
				Notiflix.Notify.Failure(
					"Password must be at least 6 characters  & contains a number and a letter"
				);
			}
		} else {
			Notiflix.Notify.Failure("Please enter new password.");
		}
	};

	const onPostPassword = () => {
		Notiflix.Loading.Dots("Please wait...");

		PostApiCall.postRequest(
			{
				email: Email,
				password: ConfirmPassword,
				salt: Salt[0].fld_salt,
				updatedby: null,
			},
			"ChangeCustomerPassword"
		).then((result) =>
			result.json().then((obj) => {
				if (result.status == 200 || result.status == 201) {
					Notiflix.Notify.Success("Password Successfully changed");
					Notiflix.Loading.Remove();
				} else {
					Notiflix.Loading.Remove();
					Notiflix.Notify.Failure("Something went wrong, try again later.");
				}
			})
		);
	};

	return (
		<div className="login-box">
			<div>
				<div className="login-modal-icon">
					<MdOutlineLockReset />
				</div>
				<div className="text-center">
					<h4 className="mb-3">
						Forgot your Password? No Worries!
					</h4>
					<p className="mb-3">Let's verify you!</p>
				</div>
				{showField && (
					<div className="row m-0 justify-content-between align-items-center mb-4">
						<input
							type="text"
							placeholder="Enter your Email Address"
							className="form-control col-md-8 mb-0"
							value={Email}
							onChange={(email) => {
								setEmail(email.target.value);
							}}></input>

						<a
							className="theme-btn text-center mt-4 cursor-pointer"
							onClick={sendConfirmation}>
							Get Code
						</a>
					</div>
				)}

				{ShowInputforOtp &&
					<div
						style={{
							position: "relative",
						}}>
						<input
							type={isPasswordVisible ? "text" : "password"}
							placeholder="Enter OTP"
							className="form-control"
							value={EnteredOtp}
							onChange={(otp) => setEnteredOtp(otp.target.value)}></input>
						<span className="login-icon-change-pass">
							<i
								style={{
									color: isPasswordVisible ? "" : "#777f80",
								}}
								className="fa fa-eye"
								onClick={() => {
									setIsPasswordVisible((prevState) => !prevState);
								}}
							/>
						</span>
					</div>
				}

				<div
					style={{
						display: openPassword == false ? "none" : "block",
					}}>
					<input
						type={isPasswordVisible ? "text" : "password"}
						placeholder="Enter new passowrd"
						className="form-control"
						value={NewPassword}
						onChange={(password) =>
							setNewPassword(password.target.value)
						}></input>
					<div className="position-relative mt-3">
						<span className="login-icon-change-pass">
							<i
								style={{
									color: isPasswordVisible ? "" : "#777f80",
								}}
								className="fa fa-eye"
								onClick={() => {
									setIsPasswordVisible((prevState) => !prevState);
								}}
							/>
						</span>
						<input
							type={isPasswordVisible ? "text" : "password"}
							placeholder="Confirm new password"
							className="form-control"
							value={ConfirmPassword}
							onChange={(password) =>
								setConfirmPassword(password.target.value)
							}></input>
						<span className="login-icon-change-pass">
							<i
								style={{
									color: isPasswordVisible ? "" : "#777f80",
								}}
								className="fa fa-eye"
								onClick={() => {
									setIsPasswordVisible((prevState) => !prevState);
								}}
							/>
						</span>
					</div>
				</div>

				{openPassword == false ? (
					<div
						className="justify-content-end  align-items-center pl-lg-3 pr-lg-3 mt-3 flex-column"
						style={{ display: showField == true ? "none" : "flex" }}>
						<a className="theme-btn w-100 text-center" onClick={VerifyOtp}>
							Verify
						</a>

						<a
							className=" float-right forgot-pass theme-btn w-100 text-center mt-3"
							data-bs-toggle="modal"
							data-bs-target="#exampleModal"
							style={{ cursor: "pointer" }}
							onClick={sendConfirmation}>
							Resend OTP
						</a>
					</div>
				) : (
					<div
						className="row justify-content-between align-items-center pl-lg-3 pr-lg-3"
						style={{ display: showField == true ? "none" : "flex" }}>
						<a className="shopping-btn mt-20" onClick={SavePassword}>
							Save new password
						</a>
					</div>
				)}

				<div className="rgbox-bottom text-center mt-2">
					<p>
						<label>Start your Shopping</label>
					</p>
				</div>

				<div className="row justify-content-around">
					<div className="but col-md-6">
						<button
							type="button"
							className="theme-btn border-0 mt-0 login-btn w-100"
							onClick={() => {
								ToggleForgotModal("Reg");
							}}>
							Sign Up
						</button>
					</div>

					<div className="but col-md-6">
						<button
							type="button"
							className="theme-btn border-0 mt-20 login-btn mt-3 mt-lg-0 w-100"
							onClick={() => {
								ToggleForgotModal("Log");
							}}>
							Login
						</button>
					</div>
				</div>
			</div>
		</div>
	);
}
