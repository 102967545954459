import React, { useState, useEffect } from "react";
import PostApiCall from "../../helpers/Api";
import Notiflix from "notiflix";
import { Modal } from "react-bootstrap";
// import Header from "./Header";
// import Footer from "./Footer";
// import OtpInput from "react-otp-input";
// import Modal from "react-responsive-modal";
// import "react-responsive-modal/styles.css";
// import ForgetImg from "./assets/img/ForgotPassword/forgetpassword.png";
// import Login from "./Login";
// import login from "./assets/img/banner/login.png";
// import { isMobile } from "react-device-detect";

var otp = Math.floor(100000 + Math.random() * 900000);

export default function ForgotPassword() {

	const [NumRegex] = useState(/^[0-9]*$/);
	const [isPasswordVisible, setIsPasswordVisible] = useState(false);
	const [AlphaNumericRegex] = useState(/^[a-zA-Z0-9]*$/);
	const [EmailRegex] = useState(
		/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
	);
	const [UrlRegex] = useState(
		/^(https:\/\/www\.|httpss:\/\/www\.|https:\/\/|httpss:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/
	);
	const [PasswordRegex] = useState(
		/^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})/
	);
	const [Email, setEmail] = useState("");
	const [open, setOpen] = useState(true);
	const [EnteredOtp, setEnteredOtp] = useState(null);
	const [ShowInputforOtp, setShowInputforOtp] = useState(false);
	const [openPassword, setOpenPassword] = useState(false);
	const [NewPassword, setNewPassword] = useState("");
	const [ConfirmPassword, setConfirmPassword] = useState("");
	const [Salt, setSalt] = useState([]);
	const [isNewPasswordVisible, setIsNewPasswordVisible] = useState(false);
	const [isConPasswordVisible, setIsConPasswordVisible] = useState(false);
	const [MobileNo, setMobileNo] = useState("");



	useEffect(() => {
		Notiflix.Loading.Init({
			svgColor: "#0f3d74",
		});
	}, [])

	const sendConfirmation = () => {
		if (Email != "") {
			if (EmailRegex.test(Email)) {
				onPostSalt();
			} else {
				Notiflix.Notify.Failure("Please enter valid Email Address.");
			}
		} else {
			Notiflix.Notify.Failure("Please enter your Email.");
		}
	}

	const onPostSalt = () => {
		PostApiCall.postRequest(
			{
				email: Email,
			},
			"Get_SaltByEmailId_ForgotPassword"
		).then((results) =>
			results.json().then((obj) => {
				if (results.status == 200 || results.status == 201) {
					if (obj.data != null) {
						setSalt(obj.data)
						onConfirmation()
					}
				} else {
					Notiflix.Notify.Failure("Email Id is not registered with us");
				}
			})
		);
	}

	const onConfirmation = () => {
		Notiflix.Loading.Dots("Please wait...");
		PostApiCall.postRequest(
			{
				OTP: otp,
				email: Email,
			},
			"Mailer_ForgotPassword"
		).then((result) => {
			Notiflix.Loading.Remove();
			Notiflix.Notify.Success(
				"Your verification OTP is Successfully sent to your Email Id."
			);
			setOpen(false)
			setShowInputforOtp(true)
		});
	};

	const VerifyOtp = () => {
		if (EnteredOtp != null) {
			if (EnteredOtp == otp) {
				setOpenPassword(true)
				setShowInputforOtp(false)
			} else {
				Notiflix.Notify.Failure("Incorrect OTP.");
			}
		} else {
			Notiflix.Notify.Failure("Please enter OTP.");
		}
	}

	const SavePassword = () => {
		if (NewPassword != "") {
			if (PasswordRegex.test(NewPassword)) {
				if (ConfirmPassword != "") {
					if (ConfirmPassword == NewPassword) {
						onPostPassword();
					} else {
						Notiflix.Notify.Failure(
							"New Password and Confirm Password do not match "
						);
					}
				} else {
					Notiflix.Notify.Failure("Please enter confirm password.");
				}
			} else {
				Notiflix.Notify.Failure(
					"Password must be at least 6 characters  & contains a number and a letter"
				);
			}
		} else {
			Notiflix.Notify.Failure("Please enter new password.");
		}
	}

	const onPostPassword = () => {
		Notiflix.Loading.Dots("Please wait...");

		PostApiCall.postRequest(
			{
				email: Email,
				password: ConfirmPassword,
				salt: Salt[0].fld_salt,
				updatedby: null,
			},
			"ChangeCustomerPassword"
		).then((result) =>
			result.json().then((obj) => {
				if (result.status == 200 || result.status == 201) {
					Notiflix.Notify.Success("Password Successfully changed");
					window.location.href = "/login";
				} else {
					Notiflix.Loading.Remove();
					Notiflix.Notify.Failure("Something went wrong, try again later.");
				}
			})
		);
	};

	return (
		<div>
			<section className="section-padding bg-grey mb-5">
				<div className="container">
					{/* <button type="button" className="goback-button" onClick={() => { this.props.history.goBack() }}><i className="fa fa-arrow-left" aria-hidden="true"></i>{" "}Go Back</button> */}
					<div className="row d-flex justify-content-center">
						<div className="col-md-8 login-page mt-5">
							<div className="login">
								<div className="row">
									<div className="col-md-12 login-section">
										<img
											// src={login}
											className="img-fluid loginimg"
											alt="loginimg"
										// style={{ display: isMobile ? "none" : "block" }}
										>
										</img>
										<div className="login-box">
											<div className="mt-4">
												<div className="text-center mb-5">
													<h2 className="mb-3">
														Forgot your Password? No Worries!
													</h2>
													<p className="mb-3">
														We want to make sure it's really you. In order to
														verify your identity tell us your email address to
														receive a verification code.{" "}
													</p>
												</div>
												<p className="mb-3">Let's verify you!</p>

												<div
													className="row m-0 justify-content-between align-items-center mb-4"
													style={{
														display:
															open == true ? "flex" : "none",
													}}>
													<input
														type="text"
														placeholder="Enter your Email Address"
														className="form-control col-md-8 mb-0"
														value={Email}
														onChange={(email) => {
															setEmail(email.target.value)
														}}></input>

													<a
														className=" btn col-md-3"
														style={{
															background: "#221f1f",
															color: "white",
														}}
														onClick={sendConfirmation}>
														Get Code
													</a>
												</div>

												<div
													style={{
														display:
															ShowInputforOtp == true
																? "block"
																: "none",
														position: "relative",
													}}>
													<input
														type={
															isPasswordVisible
																? "text"
																: "password"
														}
														placeholder="Enter OTP"
														className="form-control"
														value={EnteredOtp}
														onChange={(otp) =>
															setEnteredOtp(otp.target.value)
														}></input>
													<span className="login-icon-change-pass">
														{/* <i className="icon-dual" data-feather="lock"></i>*/}
														<i
															style={{
																color: isPasswordVisible
																	? ""
																	: "#777f80",
															}}
															className="fa fa-eye"
															onClick={() => {
																setIsPasswordVisible((prevState) => !prevState)
															}}
														/>
													</span>
												</div>
												<div
													style={{
														display:
															openPassword == false
																? "none"
																: "block",
													}}>
													<input
														type={
															isPasswordVisible
																? "text"
																: "password"
														}
														placeholder="Enter new passowrd"
														className="form-control"
														value={NewPassword}
														onChange={(password) =>
															setNewPassword(password.target.value)
														}></input>
													<div className="position-relative mt-3">
														<span className="login-icon-change-pass">
															{/* <i className="icon-dual" data-feather="lock"></i>*/}
															<i
																style={{
																	color: isPasswordVisible
																		? ""
																		: "#777f80",
																}}
																className="fa fa-eye"
																onClick={() => {
																	setIsPasswordVisible((prevState) => !prevState)
																}}
															/>
														</span>
														<input
															type={
																isPasswordVisible
																	? "text"
																	: "password"
															}
															placeholder="Confirm new password"
															className="form-control"
															value={ConfirmPassword}
															onChange={(password) =>
																setConfirmPassword(password.target.value)}>
														</input>
														<span className="login-icon-change-pass">
															<i
																style={{
																	color: isPasswordVisible
																		? ""
																		: "#777f80",
																}}
																className="fa fa-eye"
																onClick={() => {
																	setIsPasswordVisible((prevState) => !prevState)
																}}
															/>
														</span>
													</div>
												</div>

												{openPassword == false ? (
													<div
														className="row justify-content-between align-items-center pl-lg-3 pr-lg-3"
														style={{
															display:
																open == true ? "none" : "flex",
														}}>
														<a
															className="shopping-btn mt-20"
															onClick={VerifyOtp}>
															Verify
														</a>

														<a
															className="mt-2 float-right forgot-pass"
															data-bs-toggle="modal"
															data-bs-target="#exampleModal"
															style={{ cursor: "pointer" }}
															onClick={sendConfirmation}>
															Resend OTP
														</a>
													</div>
												) : (
													<div
														className="row justify-content-between align-items-center pl-lg-3 pr-lg-3"
														style={{
															display:
																open == true ? "none" : "flex",
														}}>
														<a
															className="shopping-btn mt-20"
															onClick={SavePassword}>
															Save new password
														</a>
													</div>
												)}

												<div className="rgbox-bottom text-center mt-5">
													<p>
														<label>Start your Shopping</label>
													</p>
												</div>

												<div className="row justify-content-around">
													<div className="but col-md-5">
														<button
															type="button"
															className="btn btn-common mt-20 login-btn"
															onClick={() => {
																window.location.href = "/register";
															}}>
															Sign Up
														</button>
													</div>

													<div className="but col-md-5">
														<button
															type="button"
															className="btn btn-common mt-20 login-btn"
															onClick={() => {
																window.location.href = "/login";
															}}>
															Login
														</button>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
	);
}

