import { useEffect } from "react";
import AbtTwo from "../../assets/Images/abt-2.jpg";
import ourstory from "../../assets/Images/our-story.jpg";
import GetSeoData from "../../helpers/GetSeoData";
import "./OurStory.css";
export default function OurStory() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <>
      <GetSeoData type="About Us" id={null} />
      {/* <section className="section-spacing-x section-spacing-x-mobile py-4">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-lg-3">
              <img
                // src={banner}
                className="img-fluid"
                alt=""
              />
            </div>
            <div className="col-lg-9">
              <h1 className="main-heading mb-2 mt-4 mt-md-1">Our Story</h1>
              <p className="fw-bold"></p>
            </div>
          </div>
        </div>
      </section> */}
      <section className="section-spacing-x section-spacing-x-mobile py-4">
        <div className="container-fluid">
          <div className="row">
          
            <div className="col-lg-4 pe-lg-4 story-left-img-box">
              <img src={ourstory} className="story-left-img img-fluid" />
            </div>
            <div className="col-lg-4 border-end border-start px-lg-4">
              <img
                src={AbtTwo}
                className="img-fluid h-50 w-100 mb-2 mb-lg-4"
              />
              <h2>Our Story</h2>
              <h5>Cuckoo & Crow: Embrace Your Beautiful and Ugly</h5>
              <p>
                In a world that often emphasizes perfection and conformity,
                Cuckoo &amp; Crow celebrates the beauty in imperfection. Our
                brand believes that each of us carries both a cuckoo and a crow
                within—the quirky, flawed parts alongside the beautiful,
                graceful aspects of our personalities. It's through embracing
                these dualities that we truly learn to love ourselves.
              </p>
            </div>
            <div className="col-lg-4 ps-lg-4 font-italic">
              <p className="mb-2 mb-lg-4">
                At Cuckoo & Crow, we are passionate about nurturing self-love
                from a young age. We offer a range of motivational and
                hobby-based personalized name frames for kids, designed to
                inspire self-esteem and confidence. Each frame is a reflection
                of a child's uniqueness, reminding them daily of their worth and
                potential.
              </p>
              <p className="mb-2 mb-lg-4">
                Beyond name frames, we specialize in personalized stationery
                that speaks to both children and adults alike. Our collection
                includes enchanting money envelopes and charming gift tags, each
                customizable to reflect individual tastes and preferences.
                Whether it's a birthday surprise or a heartfelt thank-you note,
                our stationery adds a personal touch that makes every occasion
                memorable.
              </p>
              <p className="mb-2 mb-lg-4">
                At the heart of Cuckoo & Crow lies a commitment to spreading
                positivity and self- acceptance. We believe that by honouring
                both our light and shadow sides, we cultivate a deeper sense of
                authenticity and compassion for ourselves and others. Join us in
                celebrating the journey of self-discovery and self-love—because
                embracing who we are, in all its complexity, is where true
                beauty resides.
              </p>
              <p>
                <b>
                  Discover Cuckoo & Crow today and celebrate the beauty of being
                  uniquely you.
                </b>
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
