import React, { useContext, useEffect, useState } from "react";
// import Menu from "./Header";
// import Footer from "./Footer";
import { AES } from "crypto-js";
import Notiflix from "notiflix";
// import PaymentSuccessImg from "../../assets/Images/payment-success.png";
import { Link, useParams } from "react-router-dom";
import PaymentSuccessImg from "../../assets/Images/paymentsucces/paymentsuccess.png";
import { store } from "../../context/store";
import PostApiCall from "../../helpers/Api";
import GetApiCall from "../../helpers/GetApi";

export default function PaymentSuccess() {
  const [mainOrder, setMainOrder] = useState([]);
  const [orderId, setOrderId] = useState(null);
  const { setcart, clientData } = useContext(store);
  const { txnid } = useParams();
  // var mobile = clientData[0]?.mobile;

  useEffect(() => {
    Notiflix.Loading.Init({
      svgColor: "#0f3d74",
    });

    const login = localStorage.getItem("CustomerData");
    const logindetails = JSON.parse(login);
    var orderid = 0;
    if (localStorage.getItem("OrderData") !== null) {
      var Order = localStorage.getItem("OrderData");
      var Orderdetails = JSON.parse(Order);
      orderid = Orderdetails[0].order_id;
    }

    Notiflix.Loading.Circle();

    GetApiCall.getRequest("GetSiteSettingsV1").then((results) =>
      results.json().then((obj1) => {
        if (results.status == 200 || results.status == 201) {
          const mobileno = obj1.data[0].mobile;
          PostApiCall.postRequest(
            {
              whereClause: `where  fld_payment_trx_num='${txnid}'`,
            },
            "GetWebsiteOrderDetails"
          ).then((results1) =>
            results1.json().then((obj) => {
              if (results1.status == 200 || results1.status == 201) {
                PostApiCall.postRequest(
                  {
                    trxid: txnid,
                    orderid: orderid,
                    //   orderid: "1",
                  },
                  "GetCustomerOrderForm"
                ).then((results) =>
                  results.json().then((obj1) => {
                    if (results.status == 200 || results.status == 201) {
                      console.log(obj1.data);

                      //  Items List
                      let items = [];
                      let formattedAddress = "";
                      let customerordernumber = "";

                      items = obj1.data.map((item) => ({
                        name: item.item_name,
                        variant: item.variant_name,
                        quantity: item.fld_quantity,
                        price: item.fld_selling_price,
                        image: item.variant_image.replace(/ /g, "%20"),
                      }));

                      // For client shipping address according last index
                      const lastIndex = obj1.data.length - 1;
                      const customershippingplace =
                        obj1.data[lastIndex].fld_shipping_address_all;

                      const formattedAddresstest = customershippingplace
                        .replace(/<br\s*\/?>/gi, "\n")
                        .replace(/<\/?span[^>]*>|<\/?b>/g, "")
                        .replace(/<[^>]+>/g, "");

                      formattedAddress = formattedAddresstest.replace(
                        /\n/g,
                        "<br />"
                      );

                      customerordernumber =
                        obj1.data[lastIndex].fld_orderNumber;

                      if (obj.data.length > 0) {
                        const paymentMode = obj.data[0]?.Payment_Mode;
                        const currentOrderId = obj.data[0]?.fld_order_id;
                        setOrderId(obj.data[0]?.fld_order_id);
                        setMainOrder(obj.data);
                        SendMailers(
                          obj.data[0]?.Total_Inclusive_Tax,
                          paymentMode,
                          currentOrderId,
                          mobileno,
                          items,
                          formattedAddress,
                          customerordernumber
                        ); // get the item amount and send it to the mailer
                      }
                    }
                  })
                );
                Notiflix.Loading.Remove();
              }
            })
          );
        }
      })
    );
  }, []);

  // send payment success mail
  const SendMailers = (
    amount,
    selectedPaymentMode,
    currentOrderId,
    mobileno,
    productsItems,
    formattedAddress,
    customerordernumber
  ) => {
    Notiflix.Loading.Dots();

    var Order = localStorage.getItem("OrderData");
    var login = localStorage.getItem("CustomerData");
    var logindetails = JSON.parse(login);

    var orderid = 0;
    if (localStorage.getItem("OrderData") !== null) {
      var Order = localStorage.getItem("OrderData");
      var Orderdetails = JSON.parse(Order);
      orderid = Orderdetails[0].order_id;
    }

    PostApiCall.postRequest(
      {
        ordernumber: txnid,
        customername: logindetails[0].fld_name,
        customeremail: logindetails[0].fld_email,
        amount: amount,
        mobile: mobileno,
        orderid: currentOrderId,
        paymentmode: selectedPaymentMode,

        // Add new details for mailer
        products: productsItems,
        shippingaddress: formattedAddress,
        customerordernumber: customerordernumber,
      },
      "CustomerOrderSuccessMailer"
    ).then((results2) =>
      results2.json().then((obj2) => {
        if (results2.status == 200 || results2.status == 201) {
          // console.log('Success')
        }
      })
    );
    if (orderId) {
      PostApiCall.postRequest(
        {
          cartID: orderId,
        },
        "PurgeCart"
      ).then((result) =>
        result.json().then((obj3) => {
          if (result.status == 200 || result.status == 201) {
            setcart(0);
          } else {
            Notiflix.Notify.Failure("Not able to process data");
          }
        })
      );
    }
  };

  const id = txnid;
  const secretKey = "mel#4321!";
  const encryptedID = AES.encrypt(
    JSON.stringify(id.toString()),
    secretKey
  ).toString();
  const urlSafeBase64 = encryptedID
    .replace(/\//g, "-")
    .toString()
    .replace("?", "bechofy");
  return (
    <div>
      <div className="container">
        <div className="row blank-section marginbtm-240">
          <div className="col-md-12 d-flex align-items-center justify-content-center my-5">
            <div className="payment-box text-center">
              <img
                src={PaymentSuccessImg}
                className="paymentFail img-fluid"
                alt="payment success"
                width="300px"
              ></img>
              <p className="fs-4 mt-2 mb-3 fw-bold text-center">
                Your order is confirmed! Sit tight, something great is headed
                your way!
              </p>
              <div className="mb-3 gap-2 d-flex align-items-center justify-content-center pl-lg-5">
                <Link
                  to={`/view-invoice-form/${orderId}/${urlSafeBase64}`}
                  className="theme-btn w-auto border-0"
                >
                  View Order
                </Link>
                <Link className="theme-btn w-auto border-0" to="/">
                  Continue Shopping
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
