import { useState, useEffect } from "react"
import "./ProductReviews.css"
import Container from "./Container";
import Notiflix from "notiflix";
import PostApiCall from "../../helpers/Api";
import { useLocation } from "react-router-dom";

export default function WriteAReview() {

	const [variantImage, setVariantImage] = useState(null)
	const [variantName, setVariantName] = useState(null)
	const [variantId, setVariantId] = useState(null)
	const [selectedVariant, setSelectedVariant] = useState([])
	const [productData, setProductData] = useState([])

	useEffect(() => {
		const queryString = window.location.search;

		// Parse the query string using URLSearchParams
		const params = new URLSearchParams(queryString);

		// Extract values by key
		const paramValue = params.get('reviewid');
		if (paramValue) {
			// Do something with the query string value
			// console.log('Query string value:', paramValue);
		}
		Notiflix.Loading.Init({
			svgColor: "#0f3d74",
		});

		Notiflix.Loading.Circle();


		PostApiCall.postRequest(
			{
				id: window.location.pathname.split('/')[2],
			},
			"GetProductByVariant"
		).then((results) =>
			results.json().then((obj) => {
				if (results.status == 200 || results.status == 201) {

					setProductData(obj.data)
					Notiflix.Loading.Remove();
					for (var i = 0; i < Object.keys(obj.data).length; i++) {
						if (obj.data[i].fld_variantid == window.location.pathname.split('/')[2]) {
							setSelectedVariant(obj.data[i])
							setVariantName(obj.data[i].fld_variantname)
							setVariantId(obj.data[i].fld_variantid)
							setVariantImage(obj.data[i].VariantImage)
						}
					}
				}
			})
		);
	}, [])


	return (
		<>
			{/* <section className="inner-banner">
				<div className="main-banner mobileinner-banner">
					<img
                            src={isMobile ? ReviewBanner : WebsiteReviewBanner}
                            alt="inner-banner"
                            className="img-responsive"
                        /> 
				</div>
			</section> */}
			<Container variantName={variantName} variantImage={variantImage} variantId={variantId} />
		</>
	)
}
