import moment from "moment";
import { useContext, useEffect, useState } from "react";
import {
  PiFacebookLogo,
  PiInstagramLogo,
  PiLinkedinLogoLight,
  PiPinterestLogo,
  PiYoutubeLogo


} from "react-icons/pi";
import { RiTwitterXLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import FooterBg from "../../assets/Images/WEBSITE_FOOTER_NEW.jpg";
import { store } from "../../context/store";
import GetApiCall from "../../helpers/GetApi";
import SocialIcons from "../Wrapper Components/SocialIcons";
import { FaWhatsapp } from "react-icons/fa";

import "./Footer.css";
export default function Footer() {
  const { clientData } = useContext(store);
  const [staticPages, setStaticPages] = useState([]);
  useEffect(() => {
    GetApiCall.getRequest("GetStaticPagesList").then((results) =>
      results.json().then((obj1) => {
        if (results.status == 200 || results.status == 201) {
          setStaticPages(obj1.data);
        }
      })
    );
  }, []);
  return (
    <>
      <footer className="pt-4 px-lg-3 border-top">
        <img src={FooterBg} className="footer-bg" />
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6 col-lg-3">
              <h3>Get In touch</h3>
              <ul>
                <li>
                  Call Us:{" "}
                  <a href={`tel:+91${clientData[0]?.mobile}`}>
                    +91 {clientData[0]?.mobile}
                  </a>
                </li>
                <li>
                  Email:{" "}
                  <a href={`mailto:${clientData[0]?.email}`}>
                    {clientData[0]?.email}
                  </a>
                </li>
              </ul>
              <ul className="main-nav-menu-sm-links d-flex py-2">
                <li>
                  <SocialIcons link={clientData[0]?.facebook} logo={<PiFacebookLogo />} />
                </li>
                <li>
                  <SocialIcons link={clientData[0]?.instagram} logo={<PiInstagramLogo />} />
                </li>
                <li>
                  <SocialIcons link={clientData[0]?.twitter} logo={<RiTwitterXLine />} />
                </li>
                <li>
                  <SocialIcons link={clientData[0]?.linkedin} logo={<PiLinkedinLogoLight />} />
                </li>
                <li>
                  <SocialIcons link={clientData[0]?.pintrest} logo={<PiPinterestLogo />} />
                </li>
                <li>
                  <SocialIcons link={clientData[0]?.youtube} logo={<PiYoutubeLogo />} />
                </li>
              </ul>
            </div>
            <div className="col-md-6 col-lg-3">
              <h3>Need Help</h3>
              <ul>
                <li>
                  <Link to={"/faqs"}>FAQs</Link>
                </li>
                <li>
                  {staticPages
                    .filter((dat) => {
                      if (
                        dat.fld_status === "Active" &&
                        dat.fld_page_name == "Return & Refund"
                      ) {
                        return dat;
                      }
                    })
                    .map((data) => {
                      return (
                        <li key={data.fld_id}>
                          <Link to={`${data.fld_route}`}>
                            {data.fld_page_name}
                          </Link>
                        </li>
                      );
                    })}
                </li>
              </ul>
            </div>
            <div className="col-md-6 col-lg-3">
              <h3>About Us</h3>
              <ul>
                {/* <li>
                  <Link to={"/our-story"}>Our Story</Link>
                </li> */}
                <li>
                  <Link to={"/contact-us"}>Contact Us</Link>
                </li>
              </ul>
            </div>
            <div className="col-md-6 col-lg-3">
              <h3>Company</h3>
              <ul>
                <li>
                  {staticPages
                    .filter((dat) => {
                      if (
                        dat.fld_status === "Active" &&
                        dat.fld_page_name != "Return & Refund" &&
                        dat.fld_page_name != "Our Story"
                      ) {
                        return dat;
                      }
                    })
                    .map((data) => {
                      return (
                        <li key={data.fld_id}>
                          <Link to={`${data.fld_route}`}>
                            {data.fld_page_name}
                          </Link>
                        </li>
                      );
                    })}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
      <div className="container-fluid px-lg-4 footer-bottom py-2">
        <div className="row flex-column-reverse flex-lg-row">
          <div className="col-12 col-lg-6 text-lg-start text-center">
            Powered by Bechofy - <br className="d-block d-md-none" /> A Product
            of
            <a href="https://globaltrendz.com/" target="blank">
              &nbsp; Global Trendz
            </a>
          </div>
          <div className="col-12 col-lg-6 text-lg-end text-center">
            <p className="mb-0">
              Copyright &copy; {moment().format("YYYY")} Cuckoo & Crow.<br className="d-block d-md-none" /> All
              Rights Reserved.
            </p>
          </div>
        </div>
      </div>

      <div class="floating_btn ">
        <a href="https://wa.me/+918920547644" class="whatsapp-btn" target="_blank"><FaWhatsapp />
        </a>
      </div>
    </>
  );
}
