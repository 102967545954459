import React, { useContext, useEffect, useState } from "react";
// import Menu from "./Header";
// import Footer from "./Footer";
import { AES } from "crypto-js";
import Notiflix from "notiflix";
// import PaymentSuccessImg from "../../assets/Images/payment-success.png";
import { Link, useParams } from "react-router-dom";
import OrderSuccessImg from "../../assets/Images/ordersuccess/ordersuccess.png";
import { store } from "../../context/store";
import PostApiCall from "../../helpers/Api";
import GetApiCall from "../../helpers/GetApi";

export default function OrderSuccess() {
	const [mainOrder, setMainOrder] = useState([]);
	const [orderId, setOrderId] = useState(null);
	const { setcart, clientData } = useContext(store);
	const { txnid } = useParams();

	useEffect(() => {
		Notiflix.Loading.Init({
			svgColor: "#0f3d74",
		});

		Notiflix.Loading.Circle();

		GetApiCall.getRequest("GetSiteSettingsV1").then((results) =>
			results.json().then((obj1) => {
				if (results.status == 200 || results.status == 201) {
					const mobileno = obj1.data[0].mobile;
					PostApiCall.postRequest(
						{
							whereClause: `where  fld_payment_trx_num='${txnid}'`,
						},
						"GetWebsiteOrderDetails"
					).then((results1) =>
						results1.json().then((obj) => {
							if (results1.status == 200 || results1.status == 201) {
								if (obj.data.length > 0) {
									const paymentMode = obj.data[0]?.Payment_Mode
									const currentOrderId = obj.data[0]?.fld_order_id
									setOrderId(obj.data[0]?.fld_order_id)
									setMainOrder(obj.data);
									SendMailers(obj.data[0]?.Total_Inclusive_Tax, paymentMode, currentOrderId, mobileno); // get the item amount and send it to the mailer
								}
								Notiflix.Loading.Remove();
							}
						})
					);
				}
			})
		);
		Notiflix.Loading.Remove()
	}, []);

	// send order success mail
	const SendMailers = (amount, selectedPaymentMode, currentOrderId, mobileno) => {
		var login = localStorage.getItem("CustomerData");
		var logindetails = JSON.parse(login);
		PostApiCall.postRequest(
			{
				ordernumber: txnid,
				customername: logindetails[0].fld_name,
				customeremail: logindetails[0].fld_email,
				amount: amount,
				mobile: mobileno,
				orderid: currentOrderId,
				paymentmode: selectedPaymentMode,
			},
			"CustomerOrderSuccessMailer"
		).then((results2) =>
			results2.json().then((obj2) => {
				if (results2.status == 200 || results2.status == 201) {
					Notiflix.Loading.Remove()
				}
			})
		);
		if (orderId) {
			PostApiCall.postRequest(
				{
					cartID: orderId,
				},
				"PurgeCart"
			).then((result) =>
				result.json().then((obj3) => {
					if (result.status == 200 || result.status == 201) {
						setcart(0);
						Notiflix.Loading.Remove()

					} else {
						Notiflix.Notify.Failure("Not able to process data");
						Notiflix.Loading.Remove()

					}
				})
			);
		}
		Notiflix.Loading.Remove()
	};

	const id = txnid;
	const secretKey = "mel#4321!";
	const encryptedID = AES.encrypt(
		JSON.stringify(id.toString()),
		secretKey
	).toString();
	const urlSafeBase64 = encryptedID
		.replace(/\//g, "-")
		.toString()
		.replace("?", "bechofy");
	return (
		<div>
			<div className="container">
				<div className="row blank-section marginbtm-240">
					<div className="col-md-12 d-flex align-items-center justify-content-center my-5">
						<div className="payment-box text-center">
							<img
								src={OrderSuccessImg}
								className="paymentFail img-fluid"
								alt="payment success"
								width="300px"></img>
							<p className="fs-4 fw-bold text-center">Your order is confirmed! Sit tight, something great is headed your way!</p>
							<div className="mb-3 gap-2 d-flex align-items-center justify-content-center pl-lg-5">
								<Link
									to={`/view-invoice-form/${orderId}/${urlSafeBase64}`}
									className="theme-btn w-auto border-0">
									View Order
								</Link>
								<Link
									className="theme-btn w-auto border-0"
									to="/">
									Continue Shopping
								</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
