import React, { useEffect, useState } from "react";
import { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import PostApiCall from "../../helpers/Api";
import "./TopBar.css";
import Slider from "react-slick";
export default function TopBar() {
  const [keyStrings, setKeyStrings] = useState([]);
  useEffect(() => {
    PostApiCall.postRequest(
      {
        whereClause: "",
      },
      "GetKeyStrings"
    ).then((results) =>
      results.json().then((obj1) => {
        if (results.status == 200 || results.status == 201) {
          setKeyStrings(
            obj1.data.filter((item) => item.FLD_Key_Name == "Top-Bar-offer" && item.FLD_Display_In == "Active")
          );
        }
      })
    );
  }, []);
  const productsliderdemo = {
    dots: false,
    infinite: false,
    autoplay: true,
    arrows: false,
    speed: 100,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 1,
  };
  return (
    <>
      <div className="container-fluid top-bar">
        <div className="row">
          <div className="col-lg-6 mx-auto text-center py-2">
            <Slider {...productsliderdemo}>
              {keyStrings.map((string, index) => (
                <p key={index} className="mb-0">
                  {string.FLD_Key_Value}
                </p>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </>
  );
}
