import moment from "moment";
import Notiflix from "notiflix";
import React, { useContext, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { AiFillEyeInvisible } from "react-icons/ai";
import { RiUser6Line, RiUserAddLine } from "react-icons/ri";
import { useHistory } from "react-router-dom";
import { store } from "../../context/store";
import PostApiCall from "../../helpers/Api";
import ForgotPassword from "../AuthModals/ForgetPassword";
import "./LoginModal.css";

export default function LoginModal({ isOpen, handleToggleModal, modalSide = "Log", handleChangeModalSide }) {
	const [numRegex, setNumRegex] = useState(/^[0-9]*$/);
	const [isPasswordVisible, setIsPasswordVisible] = useState(false);
	const [passwordFieldType, setPasswordFieldType] = useState(false);


	const [modalFace, setModalFace] = useState(modalSide)
	const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");

	const [name, setName] = useState("");
	const [mobileNo, setMobileNo] = useState("");
	const [emailAddress, setEmailAddress] = useState("");
	const [registerPassword, setRegisterPassword] = useState("");
	const [dobDay, setDobDay] = useState(1);
	const [dobMonth, setDobMonth] = useState("January");
	const [dobYear, setDobYear] = useState("2021");
	const [month, setMonth] = useState([]);
	const [day, setDay] = useState([]);
	const [year, setYear] = useState([]);
	const [gender, setGender] = useState("");
	const genderData = [
		{ label: "Male", value: "Male" },
		{ label: "Female", value: "Female" },
		{ label: "Others", value: "Others" },
	];

	const [guestLogin, setGuestLogin] = useState(true)
	const [openForgotPass, setOpenForgotPass] = useState(false);

	const { setcart, setcartamount, setLoggedIn, clientData, setCartItems, selectedFontid, selectedWishesid, perosnalizationText, showPersonalize, selectedGreetingFontid } = useContext(store)

	useEffect(() => {
		setModalFace(modalSide)
	}, [modalSide])

	const handleSwitchGuestLogin = () => {
		setGuestLogin((prevState) => {
			if (prevState == false) {
				return true
			}
			return false
		})
	}
	const ToggleForgotModal = (action) => {
		setModalFace(action)
		setOpenForgotPass(false)
	}
	const history = useHistory()


	const saveLogin = () => {
		if (email != "") {
			if (
				emailRegex.test(email) ||
				numRegex.test(email)
			) {
				if (password != "") {
					onLogin();
				} else {
					Notiflix.Notify.Failure("Please enter your password.");
				}
			} else {
				Notiflix.Notify.Failure("Please enter valid email or mobile number.");
			}
		} else {
			Notiflix.Notify.Failure("Please enter your email or mobile number.");
		}
	}

	const onLogin = (loginAfterRegister = false) => {
		Notiflix.Loading.Circle();
		PostApiCall.postRequest(
			{
				email: loginAfterRegister == true ? emailAddress : email,
				password: loginAfterRegister == true ? registerPassword : password,
				action: "Login",
				actiondate: moment().format("lll"),
			},
			"AddwebsiteLogin"
		).then((results) =>
			results.json().then((obj) => {
				if (
					(results.status == 200 || results.status == 201) &&
					localStorage.getItem("OrderData")
				) {
					localStorage.setItem("CustomerData", JSON.stringify(obj.data)); // set api user data to localstorage
					localStorage.setItem("access", JSON.stringify(obj.token)); // set the token data to access
					Notiflix.Loading.Remove();
					onPostMoveCart(obj);
					getCartdetails();
					setLoggedIn(true)
					handleToggleModal()
					clearLoginForm()
					setModalFace("Log")
				} else if (results.status == 200 || results.status == 201) {
					Notiflix.Loading.Remove();
					localStorage.setItem("CustomerData", JSON.stringify(obj.data));
					localStorage.setItem("access", JSON.stringify(obj.token));
					if (localStorage.getItem("itemdetails")) {
						addToCart();
					}
					setLoggedIn(true)
					handleToggleModal()
					getCartdetails();
					clearLoginForm()
					setModalFace("Log")
				} else {
					Notiflix.Loading.Remove();
					Notiflix.Notify.Failure(obj.data);
				}
			})
		);
	};

	const onPostMoveCart = (obj) => {
		var Order = localStorage.getItem("OrderData");
		var Orderdetails = JSON.parse(Order);

		Notiflix.Loading.Circle();

		PostApiCall.postRequest(
			{
				customerid: JSON.stringify(obj.data[0].fld_customerid),
				cartid: Orderdetails[0].order_id,
			},
			"MoveTmpCrtToMain"
		).then((results) =>
			// const objs = JSON.parse(result._bodyText)
			results.json().then((obj) => {
				if (results.status == 200 || results.status == 201) {
					localStorage.removeItem("OrderData");
					Notiflix.Loading.Remove();
				} else {
					Notiflix.Loading.Remove();
					Notiflix.Notify.Failure(obj.data);
				}
			})
		);
	}
	const addToCart = () => {
		var login = localStorage.getItem("CustomerData");
		var logindetails = JSON.parse(login);
		var Order = localStorage.getItem("OrderData");
		var Orderdetails = JSON.parse(Order);

		var itemdetail = JSON.parse(localStorage.getItem("itemdetails"));

		var itemdetails = showPersonalize === true ? `
                     [
                     {
                         "Item":${itemdetail[0]?.Item},
                         "Quantity": ${1},
						 "AttributeFont":${selectedFontid == "" ? null : selectedFontid},
						 "AttributeGreeting":${selectedWishesid == "" ? null : selectedWishesid},
						 "OrderDetails": "${perosnalizationText}",
						 "AttributeGreetingFont":${selectedGreetingFontid == "" ? null : selectedGreetingFontid}
                     }
                     ]
                     `: `
                     [
                     {
                         "Item":${itemdetail[0]?.Item},
                         "Quantity": ${1}
                     }
                     ]
                     `
		PostApiCall.postRequest(
			{
				orderdate: moment().format("lll"),
				itemdetails: itemdetails,
				status: "INCART",
				customerid:
					localStorage.getItem("CustomerData") == null
						? null
						: logindetails[0].fld_customerid,
				createdon: moment().format("lll"),
				updatedon: moment().format("lll"),
				orderid:
					localStorage.getItem("OrderData") == null
						? 0
						: Orderdetails[0].order_id,
				updatedby:
					localStorage.getItem("CustomerData") == null
						? null
						: logindetails[0].fld_userid,
			},
			"AddToCartWebsite"
		).then((result) =>
			result.json().then((obj) => {
				if (result.status == 200 || result.status == 201) {
					Notiflix.Loading.Remove();
					Notiflix.Notify.Success("Product added to Cart.");
					if (localStorage.getItem("OrderData") == null) {
						localStorage.setItem("OrderData", JSON.stringify(obj.data));
					}
					getCartdetails();
					localStorage.removeItem("itemdetails")
				} else {
					Notiflix.Loading.Remove();
					Notiflix.Notify.failure(obj.data);
				}
			})
		);
	}
	const getCartdetails = () => {
		Notiflix.Loading.Circle();
		var login = localStorage.getItem("CustomerData");
		var logindetails = JSON.parse(login);
		var Order = localStorage.getItem("OrderData");
		var Orderdetails = JSON.parse(Order);
		PostApiCall.postRequest(
			{
				orderid:
					localStorage.getItem("OrderData") == null
						? null
						: Orderdetails[0].order_id,
				id:
					localStorage.getItem("CustomerData") == null
						? null
						: logindetails[0].fld_customerid,
			},
			"GetCartWebsite"
		)
			.then((res) => res.json())
			.then((res1) => {
				if (res1.data) {
					Notiflix.Loading.Remove();

					setcart(res1.data.length);
					setcartamount(res1.data[0]?.fld_cart_amount_preview);
					setCartItems(res1.data)
				}
			});
	};

	useEffect(() => {
		Notiflix.Loading.Init({
			svgColor: "#0f3d74",
		});

		var day = [];
		var monthdata = [
			"January",
			"February",
			"March",
			"April",
			"May",
			"June",
			"July",
			"August",
			"September",
			"October",
			"November",
			"December",
		];
		var month = [];
		var year = [];

		for (var i = 1; i <= 31; i++) {
			day.push({ label: i, value: i });
		}

		const date2 = new Date();

		for (var i = 0; i < Math.abs(date2.getUTCFullYear() - 1924); i++) {
			year.push({
				label: date2.getUTCFullYear() - i,
				value: date2.getUTCFullYear() - i,
			});
		}

		for (var i = 0; i < 12; i++) {
			month.push({ label: monthdata[i], value: monthdata[i] });
		}
		setMonth(month)
		setDay(day)
		setYear(year)
	}, [])
	// ========RegistrationSave=========
	const validateGuestLogin = () => {
		if (name == "") {
			Notiflix.Notify.Failure("Please enter your name")
			return
		}
		if (emailAddress == "") {
			Notiflix.Notify.Failure("Please enter your email")
			return
		}
		if (mobileNo == "") {
			Notiflix.Notify.Failure("Please enter your mobile number")
			return
		}
		onGuestLogin()
	}
	const onGuestLogin = () => {
		var Order = localStorage.getItem("OrderData");
		var Orderdetails = JSON.parse(Order);
		Notiflix.Loading.Circle();
		PostApiCall.postRequest({
			name: name,
			email: emailAddress,
			mobile: mobileNo,
			cartid:
				localStorage?.getItem("OrderData") == null
					? null
					: Orderdetails[0].order_id,
			signupguest: "Continue",
		}, "AddGuest").then((result) =>
			result.json().then((obj) => {
				if (result.status == 200 || result.status == 201) {
					clearGuestForm()
					Notiflix.Notify.Success("Login Successfull")
					localStorage.setItem("CustomerData", JSON.stringify(obj.data))
					if (localStorage.getItem("itemdetails")) {
						addToCart();
					}
					else {
						getCartdetails();
					}
					// if (localStorage.getItem("OrderData")) {
					// 	onPostMoveCart(obj);
					// }
					Notiflix.Loading.Remove()
					setLoggedIn(true)
					handleToggleModal()
				} else {
					Notiflix.Loading.Remove();
					Notiflix.Notify.Failure(obj.data);
				}
			})
		);
	}

	const onPostRegistration = () => {
		Notiflix.Loading.Circle();

		PostApiCall.postRequest(
			{
				name: name,
				email: emailAddress,
				mobile: mobileNo,
				status: "Website",
				password: registerPassword,
				createdon: moment().format("lll"),
				updatedon: moment().format("lll"),
				updatedby: 0,
				dob:
					dobYear +
					"-" +
					dobMonth +
					"-" +
					dobDay,
				gender: gender,
			},
			"AddCustomer"
		).then((result) =>
			result.json().then((obj) => {
				if (result.status == 200 || result.status == 201) {
					onLogin(true)
				} else {
					Notiflix.Loading.Remove();
					Notiflix.Notify.Failure(obj.data);
				}
			})
		);
	};
	const clearGuestForm = () => {
		setName("")
		setEmailAddress("")
		setMobileNo("")
	}

	const SaveRegistration = () => {
		if (name != "") {
			if (emailAddress != "") {
				if (emailRegex.test(emailAddress)) {
					if (mobileNo != "") {
						if (mobileNo.length == 10) {
							if (registerPassword != "") {
								onPostRegistration();
							} else {
								Notiflix.Notify.Failure("Please enter password.");
							}
						} else {
							Notiflix.Notify.Failure("Please enter valid mobile number.");
						}
					} else {
						Notiflix.Notify.Failure("Please enter mobile number.");
					}
				} else {
					Notiflix.Notify.Failure("Please enter valid email address.");
				}
			} else {
				Notiflix.Notify.Failure("Please enter email address.");
			}
		} else {
			Notiflix.Notify.Failure("Please enter your name.");
		}
	}

	const clearRegistrationForm = () => {
		setEmailAddress("")
		setMobileNo("")
		setRegisterPassword("")
		setName("")
		setGender("")
	}
	const clearLoginForm = () => {
		setPassword("")
		setEmail("")
	}
	return (
		<>
			<Modal show={isOpen} centered onHide={() => {
				handleToggleModal();
				// setGuestLogin(false);
				clearGuestForm();
				clearLoginForm();
				clearRegistrationForm();
			}} >
				<Modal.Header closeButton className="border-0 pb-0" />
				<Modal.Body className="px-3 pt-0">
					{/* Login modal */}
					{modalFace == "Log" && !guestLogin && !openForgotPass && (
						<div className="login-box pb-0">
							<div className="login-modal-icon">
								<RiUser6Line />
							</div>
							<div className="section-title mb-0 pb-3 mt-0">
								<h4 className="text-center">
									Get Fabulous Deals!
								</h4>
							</div>
							<p style={{ textAlign: "center", marginTop: "-10px" }}>
								Login to Order Online
							</p>

							<div style={{ marginTop: "20px" }}>
								<input
									type="text"
									placeholder="Use your Email ID or Phone No. to Sign in"
									className="form-control"
									value={email}
									onChange={(email) => {
										setEmail(email.target.value)
									}}></input>
							</div>
							<div style={{ marginTop: "10px", position: "relative" }}>
								<input
									type={passwordFieldType ? "text" : "password"}
									placeholder="Password"
									className="form-control"
									value={password}
									onChange={(password) => {
										setPassword(password.target.value)
									}}></input>
								<AiFillEyeInvisible
									role={"button"}
									onClick={() =>
										setPasswordFieldType(!passwordFieldType)
									}
									style={{
										position: "absolute",
										right: "5%",
										bottom: "36%",
									}}
								/>
							</div>

							<div className=" forgot-password mt-2 d-flex justify-content-end align-items-center">
								<span
									className=" float-right forgot-pass small"
									data-bs-toggle="modal"
									data-bs-target="#exampleModal"
									style={{ cursor: "pointer" }}
									onClick={() => {
										setOpenForgotPass(true)
									}}>
									Forgot Password?
								</span>
							</div>
							<div className="text-center cursor-pointer">
								<button
									className="theme-btn w-100 d-block my-2 border-0"
									onClick={saveLogin}>
									Login
								</button>
							</div>
							<div className="text-center" style={{ marginTop: "10px" }}>
								<div
									className="newTowebsite-register"
									style={{ display: "flex", justifyContent: "center" }}>
									<p>New to {clientData[0]?.store}?</p>
									<p
										style={{
											marginLeft: "5px",
											cursor: "pointer",
											color: "#221f1f",
											fontWeight: "bold",
										}}
										onClick={() => {
											// handleChangeModalSide("Reg")
											setModalFace("Reg")
											setPasswordFieldType(false)
										}}>
										Register Now!
									</p>
								</div>
								<p className="text-center lef-rigth-border">OR</p>
								<div className="col-md-12 mb-2">
									<button
										onClick={() => {
											handleSwitchGuestLogin()
										}}
										className="theme-btn w-100 d-block my-2 text-center border-0">
										Continue as Guest
									</button>
								</div>
							</div>
						</div>
					)}

					{/* registration modal */}
					{modalFace == "Reg" && !guestLogin && (
						<div className="login-box pb-0">
							<div className="section-title mb-0 pb-3 mt-0">
								<div className="login-modal-icon">
									<RiUserAddLine />
								</div>
								<h4 className="text-center">
									New to {clientData[0]?.store}?
								</h4>
							</div>
							<p
								style={{
									textAlign: "center",
									marginTop: "-5px",
									marginBottom: "30px",
								}}>
								Shop with us & get attractive deals
							</p>

							<div className="row">
								<div className="col-md-12">
									<input
										type="text"
										placeholder="Name"
										className="form-control"
										value={name}
										onChange={(name) => {
											setName(name.target.value)
										}}></input>
								</div>
								<div className="col-md-12" style={{ marginTop: "10px" }}>
									<input
										type="text"
										placeholder="Email ID"
										className="form-control"
										value={emailAddress}
										onChange={(email) => {
											setEmailAddress(email.target.value)
										}}></input>
								</div>
								<div className="col-md-12" style={{ marginTop: "10px" }}>
									<input
										type="text"
										placeholder="Mobile Number"
										className="form-control"
										value={mobileNo}
										onChange={(mobile) => {
											if (
												numRegex.test(mobile.target.value) &&
												mobile.target.value.length <= 10
											) {
												setMobileNo(mobile.target.value)
											}
										}}
									/>
								</div>
								<div
									className="col-md-12"
									style={{ marginTop: "10px", position: "relative" }}>
									<input
										placeholder="Password"
										className="form-control"
										type={isPasswordVisible ? "text" : "password"}
										value={registerPassword}
										onChange={(pswd) => {
											setRegisterPassword(pswd.target.value)
										}}
									/>
									<AiFillEyeInvisible
										role={"button"}
										onClick={() =>
											setIsPasswordVisible(!isPasswordVisible)
										}
										style={{
											position: "absolute",
											right: "5%",
											bottom: "36%",
										}}
									/>
								</div>

								<div className="col-md-12" style={{ marginTop: "10px" }}>
									<select
										className="form-control"
										value={gender}
										onChange={(text) => {
											setGender(text.target.value)
										}}>
										<option value=""> Gender</option>
										{genderData.map((data, i) => (
											<option value={data.value} key={data.value}>
												{data.label}
											</option>
										))}
									</select>
								</div>

								<div className="col-md-12 d-flex align-items-md-center my-2">
									<input type="checkbox" />
									<label className="ms-1">
										By Registering, you agree to our <a href="/terms-conditions">Terms & Conditions</a>.
									</label>
								</div>
								<div className="col-md-12 mb-2">
									<button
										onClick={SaveRegistration}
										className="theme-btn w-100 d-block my-2 text-center border-0">
										Register
									</button>
								</div>
								<div className="col-12 text-center">
									<div
										className="newTowebsite-register"
										style={{ display: "flex", justifyContent: "center" }}>
										<p>Already an user?</p>
										<p
											style={{
												marginLeft: "5px",
												cursor: "pointer",
												color: "#221f1f",
												fontWeight: "bold",
											}}
											onClick={() => {
												setModalFace("Log")
												setIsPasswordVisible(false)
											}}>
											Login!
										</p>
									</div>
									<p className="text-center">OR</p>
									<div className="col-md-12 mb-2">
										<button
											onClick={() => { handleSwitchGuestLogin() }}
											className="theme-btn w-100 d-block my-2 text-center border-0">
											Continue as Guest
										</button>
									</div>
								</div>
							</div>
						</div>
					)}
					{/* guest login */}
					{guestLogin && (
						<>
							<div className="section-title mb-0 pb-3 mt-0">
								<div className="login-modal-icon">
									<RiUser6Line />
								</div>
								<h4 className="text-center">
									Seamless Experience with Guest Login!
								</h4>
							</div>
							<p
								style={{
									textAlign: "center",
									marginTop: "-5px",
									marginBottom: "30px",
								}}>
								Start Shopping Instantly!
							</p>

							<div className="row">
								<div className="col-md-12">
									<input
										type="text"
										placeholder="Name"
										className="form-control"
										value={name}
										onChange={(name) => {
											setName(name.target.value)
										}}></input>
								</div>
								<div className="col-md-12" style={{ marginTop: "10px" }}>
									<input
										type="text"
										placeholder="Email ID"
										className="form-control"
										value={emailAddress}
										onChange={(email) => {
											setEmailAddress(email.target.value)
										}}></input>
								</div>
								<div className="col-md-12" style={{ marginTop: "10px" }}>
									<input
										type="text"
										placeholder="Mobile Number"
										className="form-control"
										value={mobileNo}
										onChange={(mobile) => {
											if (
												numRegex.test(mobile.target.value) &&
												mobile.target.value.length <= 10
											) {
												setMobileNo(mobile.target.value)
											}
										}}
									/>
								</div>
								<div className="col-md-12 mb-2">
									<button
										onClick={validateGuestLogin}
										className="theme-btn w-100 d-block my-2 text-center border-0">
										Continue
									</button>
								</div>
								<div
									className="newTowebsite-register"
									style={{ display: "flex", justifyContent: "center" }}>
									<p>Already an user?</p>
									<p
										style={{
											marginLeft: "5px",
											cursor: "pointer",
											color: "#221f1f",
											fontWeight: "bold",
										}}
										onClick={() => {
											handleChangeModalSide("Log");
											handleSwitchGuestLogin()
										}}>
										Login
									</p>
								</div>
							</div>
						</>
					)
					}
					{/* Forget Password modal */}
					{openForgotPass &&
						<ForgotPassword ToggleForgotModal={ToggleForgotModal} />
					}
				</Modal.Body>
			</Modal>
		</>

	);
}

