import parse from "html-react-parser";
import moment from "moment";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import "./BlogsSection.css";
import { Skeleton } from "antd";
export default function BlogsSection({ blogData, isLoading }) {
  return (
    <div className="section-spacing-x section-spacing-x-mobile">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-6 mx-auto text-center main-heading-box">
            <h2 className="main-heading">Event Inspirations</h2>
            <p>
              Dive into Floral Insights: Tips, Inspirations, and Stories
            </p>
          </div>
        </div>
        <div className="row">
          {
            isLoading === false ?
              <div className="col-md-6 col-lg-4 mb-4 mb-lg-0">
                <div className="blog-card border">
                  <Skeleton.Image
                    className="img-fluid w-100 skel-blog-img mb-2"
                  />
                  {/* <span className="date-box">
                    <Skeleton.Input />
                  </span> */}
                  <div className="content">
                    <Skeleton.Input className="title py-0" />
                    <div>
                      <Skeleton.Input className="blog-des w-100 pb-5" />
                    </div>
                    <div className="text-start">
                      <Skeleton.Button />
                    </div>
                  </div>
                </div>
              </div>

              :
              <>
                {blogData.length > 0
                  ? blogData?.map((data, index) => {
                    return (
                      <div
                        className="col-md-6 col-lg-4 mb-4 mb-lg-0"
                        key={`blog-${index}`}>
                        <div className="blog-card border">
                          <Link to={{
                            pathname: `/blog-detail/${data.fld_title.toLowerCase().trim().split(" ").join("-")}`,
                            state: { blogData: data },
                          }}>
                            <img
                              alt=""
                              src={data.fld_blogimage}
                              className="img-fluid w-100"
                            />
                          </Link>
                          <span className="date-box">
                            <p>
                              {moment(data.fld_createdon, "DD/MM/YYYY").format(
                                "DD"
                              )}
                            </p>
                            <b>
                              {moment(data.fld_createdon, "DD/MM/YYYY").format(
                                "MMM"
                              )}
                            </b>
                            <b>
                              {moment(data.fld_createdon, "DD/MM/YYYY").format(
                                "YYYY"
                              )}
                            </b>
                          </span>
                          <div className="content">
                            <p className="title mt-2 mb-2"><Link to={{
                              pathname: `/blog-detail/${data.fld_title.toLowerCase().trim().split(" ").join("-")}`,
                              state: { blogData: data },
                            }}>{data.fld_title}</Link></p>
                            <p className="blog-des mb-0">{parse(data.fld_short_description)}</p>
                            <div className="text-start mt-3">
                              <Link to={{
                                pathname: `/blog-detail/${data.fld_title.toLowerCase().trim().split(" ").join("-")}`,
                                state: { blogData: data },
                              }} className="theme-btn">
                                Read more
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                  : ""}
              </>
          }

          {/* <div className="col-md-6 col-lg-4 mb-4 mb-lg-0">
            <div className="blog-card">
              <img alt="" src={NoImg} className="img-fluid" />
              <div className="content">
                <p className="title">Blog Title</p>
                <p>
                  The href attribute requires a valid value to be accessible.
                  Provide a valid, navigable address as the href value. The href
                  attribute requires a valid value to be accessible. Provide a
                  valid, navigable address as the href value.
                </p>
                <Link to="/" className="theme-btn">
                  Read more
                </Link>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 mb-4 mb-lg-0">
            <div className="blog-card">
              <img alt="" src={NoImg} className="img-fluid" />
              <div className="content">
                <p className="title">Blog Title</p>
                <p>
                  The href attribute requires a valid value to be accessible.
                  Provide a valid, navigable address as the href value. The href
                  attribute requires a valid value to be accessible. Provide a
                  valid, navigable address as the href value.
                </p>
                <Link to="/" className="theme-btn">
                  Read more
                </Link>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
}
