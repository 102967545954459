import { Link } from "react-router-dom";
import bannerImg from "../../../assets/Images/kids-banner.jpg";
import "./CategorySectionGrid.css";
export default function CategorySectionGrid({ categoryData }) {
  return (
    <div className="px-lg-3">
      <div className="container-fluid pt-2">
        <div className="row pt-1">
          <div className="col-lg-6 pe-lg-4 order-2 order-lg-1">
            <div className="row">
              {categoryData?.slice(0, 4).map((data) => {
                return (
                  <Link to={`/p/${data.fld_route?.replace(
                    /\s/g,
                    "-"
                  ).replace('/', '')}`} className="col-6 pe-lg-0 mb-2 pb-1 category-section-grid-right">
                    <img src={data.VariantImage} className="img-fluid" />
                  </Link>
                );
              })}
            </div>
          </div>
          <div className="col-lg-6 ps-lg-0 order-1 order-lg-2">
            <img
              src={bannerImg}
              className="img-fluid"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
