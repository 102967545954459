import { Input } from "antd";
import TextArea from "antd/es/input/TextArea";
import Notiflix from "notiflix";
import { useContext, useEffect, useState } from "react";
import { FaHome, FaWhatsapp } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import { IoCall, IoMail } from "react-icons/io5";
import { TfiReload } from "react-icons/tfi";
import { store } from "../../context/store";
import "./ContactPage.css";
import PostApiCall from "../../helpers/Api";
import GetSeoData from "../../helpers/GetSeoData";

export default function ContactPage() {
    useEffect(() => {
        generateRandomCaptcha();
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }, []);

    const { clientData } = useContext(store);
    const [captcha, setCaptcha] = useState("");
    const [enteredCaptcha, setEnteredCaptcha] = useState("");
    const [formData, setFormData] = useState({
        name: "",
        mobile: "",
        email: "",
        message: "",
    });
    const handleRegexValidation = (email, phone) => {
        let result;
        if (email) {
            result = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
        } else {
            result = /^\d{10}$/.test(phone);
        }
        return result;
    };
    const generateRandomCaptcha = () => {
        const newCaptcha = Math.random().toString(36).slice(2, 7).toUpperCase();
        setCaptcha(newCaptcha);
    };
    function isCaptchaValid(enteredCaptcha) {
        return captcha == enteredCaptcha;
    }
    const handleInputChange = (name, value) => {
        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    function handleValidateForm() {
        Notiflix.Loading.Dots("Please wait...");

        if (formData.name === "") {
            Notiflix.Notify.Failure("Name is required");
            Notiflix.Loading.Remove();
            return;
        }
        if (formData.mobile === "") {
            Notiflix.Notify.Failure("Phone number is required");
            Notiflix.Loading.Remove();
            return;
        } else if (!handleRegexValidation("", formData.mobile)) {
            Notiflix.Notify.Failure("Invalid phone number");
            Notiflix.Loading.Remove();
            return;
        }
        if (formData.email == "") {
            Notiflix.Notify.Failure("Email address is required");
            Notiflix.Loading.Remove();
            return;
        } else if (!handleRegexValidation(formData.email, "")) {
            Notiflix.Notify.Failure("Invalid email address");
            Notiflix.Loading.Remove();
            return;
        }
        if (formData.message === "") {
            Notiflix.Notify.Failure("Message is required");
            Notiflix.Loading.Remove();
            return;
        }
        if (!isCaptchaValid(enteredCaptcha)) {
            Notiflix.Notify.Failure("Invalid Captcha");
            Notiflix.Loading.Remove();
            return;
        }
        onSubmit();
    }

    function clearFormData() {
        setFormData({
            name: "",
            mobile: "",
            email: "",
            message: "",
        });
    }

    function onSubmit() {
        PostApiCall.postRequest(
            {
                name: formData.name,
                mobile: formData.mobile,
                email: formData.email,
                message: formData.message,
            },
            "ContactUsMailer"
        ).then((results) =>
            results.json().then((obj) => {
                if (results.status === 200 || results.status === 201) {
                    Notiflix.Loading.Remove();
                    clearFormData();
                    // generateRandomCaptcha();
                    Notiflix.Notify.Success("Form Submitted Successfully.");
                } else {
                    Notiflix.Notify.Failure("Something went wrong!");
                }
            })
        );
    }

    return (
        <>
            <GetSeoData type="Contact Us" id={null} />
            <section className="section-spacing-x section-spacing-x-mobile py-4">
                <div className="container-fluid">
                    <h1 className="text-center main-heading">Get in Touch with Us!</h1>
                    <div className="row flex-lg-row flex-column-reverse">
                        <div className="col-lg-6">
                            <h4 className="main-heading mb-0 mt-4 mt-md-1">Contact info</h4>
                            <div className="contact-info-box">
                                <div className="icon">
                                    <FaLocationDot />
                                </div>
                                <div>
                                    <p>Our Office</p>
                                    {clientData[0]?.store} <br />
                                    <a
                                        href="https://maps.app.goo.gl/SnAckVGBQRsbdcQJ7"
                                        target="_blank">
                                        {/* B 7/1, near Rajouri Garden, Upper Ground,
                    <br />
                    Rajouri Garden, New Delhi, Delhi - 110 027, INDIA. */}
                                        {clientData[0]?.address}
                                    </a>
                                </div>
                            </div>
                            <div className="contact-info-box">
                                <div className="icon">
                                    <IoCall />
                                </div>
                                <div>
                                    <p>Call Us</p>
                                    <a href={`tel:+91${clientData[0]?.mobile}`}>
                                        +91 {clientData[0]?.mobile}
                                    </a>
                                </div>
                            </div>
                            <div className="contact-info-box">
                                <div className="icon">
                                    <FaWhatsapp />
                                </div>
                                <div>
                                    <p>Message Us</p>
                                    <a href={`https://wa.me/+91${clientData[0]?.mobile}`}>
                                        {clientData[0]?.mobile}
                                    </a>
                                </div>
                            </div>
                            <div className="contact-info-box">
                                <div className="icon">
                                    <IoMail />
                                </div>
                                <div>
                                    <p>Mail Us</p>
                                    <a href={`mailto:${clientData[0]?.email}`}>
                                        {clientData[0]?.email}
                                    </a>
                                </div>
                            </div>
                        </div>
                        {/*  Contact form on the right side */}
                        <div className="col-lg-6">
                            <h4 className="mb-1">
                                Welcome to <strong>Cuckoo & Crow !</strong>
                            </h4>
                            <p>We value your feedback, inquiries,
                                and support. Whether you have questions about our products, need
                                assistance with an order, or just want to say hello, we are here
                                to help. Feel free to reach out to us by filling the form below:</p>
                            <div className="row contact-form mt-3">
                                <div className="col-lg-6 mb-4">
                                    <Input
                                        placeholder="Enter your name"
                                        value={formData.name}
                                        onChange={(e) => handleInputChange("name", e.target.value)}
                                    />
                                </div>
                                <div className="col-lg-6 mb-4">
                                    <Input
                                        placeholder="Phone no"
                                        value={formData.mobile}
                                        onChange={(e) =>
                                            handleInputChange("mobile", e.target.value)
                                        }
                                    />
                                </div>
                                <div className="col-12 mb-4">
                                    <Input
                                        placeholder="Email Address"
                                        value={formData.email}
                                        onChange={(e) => handleInputChange("email", e.target.value)}
                                    />
                                </div>
                                <div className="col-12 mb-4">
                                    <TextArea
                                        rows={5}
                                        placeholder="Message"
                                        value={formData.message}
                                        onChange={(e) =>
                                            handleInputChange("message", e.target.value)
                                        }
                                    />
                                </div>
                                <div className="form-group col-lg-6 mb-4 pb-0">
                                    <div className="captcha-box">
                                        <input
                                            type="text"
                                            value={captcha}
                                            disabled
                                            className="form-control"
                                        />
                                        <div
                                            className="captcha"
                                            onClick={() => generateRandomCaptcha()}>
                                            <TfiReload />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group col-lg-6 mb-4">
                                    <input
                                        type="text"
                                        value={enteredCaptcha}
                                        onChange={(e) =>
                                            setEnteredCaptcha(e.target.value.toUpperCase())
                                        }
                                        className="form-control"
                                        placeholder="Enter Captcha *"
                                    />
                                </div>
                                <div className="col-12">
                                    <button
                                        className="theme-btn border-0"
                                        onClick={handleValidateForm}>
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
